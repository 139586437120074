<div class="status-toggle-component" [class]="configuration.direction">

  <button
    *ngFor="let button of configuration.values"
    mat-fab
    extended
    [ngStyle]="{
    'color': configuration.control?.value === button.value ? 'white' : getColor(button),
    'background-color': configuration.control?.value === button.value ? getColor(button) : ''}"
    (click)="setValue(button.value)"
    [disabled]="isDisabled(button)"
  >
    <mat-icon *ngIf="button.icon"> {{ button.icon }}</mat-icon>
    {{ button.label | translate }}
  </button>

</div>
