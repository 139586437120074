<p-table
  #table
  class="selected-items-table"
  [value]="configuration?.itemHandlings ?? []"
  editMode="row">

  <ng-template pTemplate="header">

    <tr>
      <th [hidden]="!configuration.isItemHandlingTableMode"> {{ 'UpdateAt' | translate }}</th>
      <th> {{ 'ItemName' | translate }}</th>
      <th style="text-align: center"
          [hidden]="!configuration.isItemHandlingTableMode || !viewModel.handlingIsCheckInState"> {{ 'HandlingState' | translate }}
      </th>
      <th style="text-align: center; width: 200px"> {{ 'startQty' | translate }}</th>
      <th style="text-align: center; width: 200px"
          [hidden]="!configuration.isItemHandlingTableMode || viewModel.handlingIsDraftState"> {{ 'partialQty' | translate }}
      </th>  <!-- Barcode handling?-->
      <th style="text-align: center; width: 200px"
          [hidden]="!configuration.isItemHandlingTableMode || !viewModel.handlingIsCheckInState"> {{ 'currentProcessedQty' | translate }}
      </th>
      <th style="text-align: center; width: 200px"
          [hidden]="!configuration.isItemHandlingTableMode || viewModel.handlingIsDraftState"> {{ 'remainingQty' | translate }}
      </th>
      <th style="text-align: center"> {{ 'UMSymbol' | translate }}</th>
      <th [hidden]="!configuration.isItemHandlingTableMode"></th>
    </tr>

  </ng-template>

  <ng-template pTemplate="body" let-itemHandling let-editing="editing" let-ri="rowIndex">

    <tr [pEditableRow]="itemHandling">

      <td [hidden]="!configuration.isItemHandlingTableMode">

        {{ (itemHandling.UpdateAt ?? currentDate) | date: 'dd/MM/yyyy' }}

      </td>

      <td>

        <p>
          <strong>{{ itemHandling?.Item.Code }}</strong>- {{ itemHandling?.Item.Name }}
        </p>
        <small> {{ itemHandling?.Item.Description }} </small>

      </td>

      <td [hidden]="!configuration.isItemHandlingTableMode || !viewModel.handlingIsCheckInState">

        <app-status-toggle-component
          *ngIf="itemHandling.StatusToggleComponentConfiguration"
          [configuration]="itemHandling.StatusToggleComponentConfiguration"></app-status-toggle-component>

      </td>

      <td style="text-align: center">

        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="number"
              min="0"
              [disabled]="configuration.isItemHandlingTableMode && !viewModel.handlingIsDraftState"
              [(ngModel)]="itemHandling.StartQty"
              [max]="itemHandling.BeaverItemStock?.Stock ?? null"
              name="startQty"
              #startQtyInput="ngModel">

            <small class="error-message"
                   *ngIf="startQtyInput.invalid && (startQtyInput.dirty || startQtyInput.touched)"> Inserisci un valore
              valido </small>
          </ng-template>
          <ng-template pTemplate="output">
            {{ itemHandling.StartQty }}
          </ng-template>
        </p-cellEditor>

      </td>

      <td style="text-align: center"
          [hidden]="!configuration.isItemHandlingTableMode || viewModel.handlingIsDraftState">

        <!--        <div *ngIf="(configuration?.barcodes ?? []).length; else qtyInsert">-->

        <!--          <button mat-raised-button (click)="onTracking.emit()"-->
        <!--                  class="m-t-15"> {{ 'barcodeTracking.trackFourBarcodesLabel'| translate:{count: configuration.barcodes.length} }}-->
        <!--          </button>-->

        <!--        </div>-->

        {{ itemHandling.Qty }}

      </td>

      <td style="text-align: center"
          [hidden]="!configuration.isItemHandlingTableMode || !viewModel.handlingIsCheckInState">

        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              type="number"
              min="0"
              [disabled]="itemHandling.StatusToggleComponentConfiguration?.control?.value !== HandlingStateEnum.Partial"
              (change)="itemHandling.PartialQty < 0 ? itemHandling.PartialQty = 0 : itemHandling.PartialQty"
              [(ngModel)]="itemHandling.PartialQty">
          </ng-template>
          <ng-template pTemplate="output">
            {{ itemHandling.PartialQty }}
          </ng-template>
        </p-cellEditor>

      </td>

      <td style="text-align: center"
          [hidden]="!configuration.isItemHandlingTableMode || viewModel.handlingIsDraftState">

        {{ getRemainingQuantity(itemHandling) > 0 ? getRemainingQuantity(itemHandling) : 0 }}

      </td>

      <td style="text-align: center">

        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown
              dataKey="Oid"
              [options]="availableUMs"
              optionLabel="Symbol"
              [disabled]="!viewModel.handlingIsDraftState"
              [(ngModel)]="itemHandling.UM"
              appendTo="body"
              [style]="{'width':'100%'}"></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ itemHandling.UM?.Symbol }}
          </ng-template>
        </p-cellEditor>

      </td>

      <td [hidden]="!configuration.isItemHandlingTableMode">
        <div class="flex align-items-center justify-content-center gap-2">

          <button
            *ngIf="configuration?.isItemHandlingTableMode && viewModel.handlingIsDraftState"
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            (click)="deleteRow( ri)"
            class="p-button-rounded p-button-text p-button-danger"></button>

        </div>
      </td>

    </tr>
  </ng-template>
</p-table>
