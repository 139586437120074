<div
  class="module-add-item-cntr">

  <div
    mat-dialog-content>

    <app-item-search
      [configuration]="itemSelectionConfiguration"
      (onSelected)="itemSelectionService.updateSelection($event, selectedItemsConfiguration)"
      (onImport)="selectedItemsConfiguration.updateSelected($event)"></app-item-search>

    <mat-divider></mat-divider>

    <app-selected-items
      *ngIf="selectedItemsConfiguration.itemHandlings.length"
      [configuration]="selectedItemsConfiguration"></app-selected-items>

    <div
      mat-dialog-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">
      <button mat-button mat-dialog-close> {{ "close" | translate }}</button>
      <button *ngIf="selectedItemsConfiguration.itemHandlings.length" mat-button (click)="submit()" color="primary"
              cdkFocusInitial> {{ "CT_PAGE.CT_FORM.save" | translate }}
      </button>
    </div>

  </div>

</div>
