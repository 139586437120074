import {Component, Inject, OnInit} from "@angular/core";
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelDatatableOperators, CtSortOrderDescriptorParameter,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {SelectionType} from "@swimlane/ngx-datatable";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {ItemSelectionConfiguration} from "./item-selection.configuration";
import {ITEM_STOCK_SELECTION_COLUMNS} from "./item-stock.selection-columns";
import {ITEM_SELECTION_COLUMNS} from "./item.selection-column";
import {ItemController} from "../../../../../../_core/controllers/item.controller";
import {LocationStockController} from "../../../../../../_core/controllers/location-stock.controller";
import {HandlingOriginEnum} from "../../../../../../_core/enum/handling-origin.enum";
import {ItemState} from "../../../../../../_core/enum/item-state";
import {BeaverItem} from "../../../../../../_core/classes/beaver-item";
import {BeaverItemStock} from "../../../../../../_core/classes/beaver-item-stock";
import {environment} from "../../../../../../../environments/environment";

@Component({
  selector: "app-item-selection",
  templateUrl: "./item-selection.component.html",
  styleUrls: ["./item-selection.component.scss"],
  providers: [LocationStockController, ItemController]
})
export class ItemSelectionComponent implements OnInit {

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => this.onSubmit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  list: CtDatatableConfiguration = CtDatatableConfiguration.create()
    .setLimit(5)
    .setSelectionType(SelectionType.multiClick)
    .setRowHeight(80)
    .setScrollbarH(true);

  constructor(
    private locationStockController: LocationStockController,
    private itemController: ItemController,
    @Inject(MAT_DIALOG_DATA) private data: ItemSelectionConfiguration,
    private dialog: MatDialogRef<ItemSelectionComponent>
  ) {
  }

  ngOnInit() {

    if (this.data.selectedItemsConfiguration) {

      const selectedItemRows = this.data.selectedItemsConfiguration.itemHandlings.map(elm => elm.BeaverItemStock);

      this.list
        .setSelected(selectedItemRows);

    }

  }

  ngAfterViewInit(): void {

    setTimeout(() => {

      window.dispatchEvent(new Event("resize"));

    }, 250);

  }

  getFilters(): CTModelDatatableFilter[] {

    let filters: CTModelDatatableFilter[] = new Array<CTModelDatatableFilter>();

    switch (this.data.origin) {

      case HandlingOriginEnum.Supplier:

        filters = [

          CTModelDatatableFilter
            .create()
            .setField("State")
            .setValue(ItemState.Enabled)
            .setOperatorType(CtBinaryOperator.Equal)

        ];

        if (this.data.from && this.data.from !== environment.variables.defaults.inventoryOid) {

          filters.push(
            CTModelDatatableFilter
              .create()
              .setField("Supplier.Oid")
              .setValue(this.data.from)
              .setOperatorType(CtBinaryOperator.Equal));

        }

        break;

      case HandlingOriginEnum.Location:

        filters = [

          CTModelDatatableFilter
            .create()
            .setField("Location.Oid")
            .setValue(this.data.from)
            .setOperatorType(CtBinaryOperator.Equal)

        ];

        break;

    }

    if (this.data.searchText) {

      filters
        .push(CTModelDatatableFilter
          .create()
          .setField("ItemCodeName")
          .setValue(this.data.searchText)
          .setOperatorType(CtBinaryOperator.Contains));

    }

    if (this.data.defaultFilters) {

      filters = filters.concat(this.data.defaultFilters)

    }

    return filters;

  }

  fetch(pagination?: CTDatatablePaginationParameter): void {

    this.list.setLoadingIndicator(true);

    this.caller(pagination)
      .then((result: CtModelConfiguration<Array<BeaverItem | BeaverItemStock>>) => {

        this.list
          .setCount(result.TotalElement ?? 0)
          .setRows(result.DataSource as BeaverItemStock[])
          .setLoadingIndicator(false);

      });

  }

  caller(pagination?: CTDatatablePaginationParameter): Promise<CtModelConfiguration<BeaverItemStock[]>> {

    const operators: CtModelDatatableOperators = CtModelDatatableOperators.create()
      .setFilters(this.getFilters())

    if (pagination) {

      operators
        .setPagination(pagination);

    }

    operators
      .Pagination
      ?.setSortOrders(
        new Array<CtSortOrderDescriptorParameter>(
          new CtSortOrderDescriptorParameter()
            .setField(this.data.origin === HandlingOriginEnum.Supplier ? 'Name' : 'ItemName')
            .setIsDescending(false)
        )
      )

    if (this.data.origin === HandlingOriginEnum.Supplier) {

      this.list
        .setColumns(ITEM_SELECTION_COLUMNS);

      return this.itemController
        .get(operators);

    } else {

      this.list
        .setColumns(ITEM_STOCK_SELECTION_COLUMNS);

      return this.locationStockController
        .get(operators);

    }

  }

  onCancel() {

    this.dialog
      .close();

  }

  onSubmit() {

    let selected = this.list.selected;

    if (this.data.origin === HandlingOriginEnum.Supplier) {

      selected = ((selected ?? []) as BeaverItem[])
        .map(item => BeaverItemStock.createByBeaverItem(item));

    }

    this.dialog
      .close(selected);

  }

}
