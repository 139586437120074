import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() {
  }

  blobDownload(blob: Blob, filename = `${environment.projectName}_${new Date().getTime()}`) {

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);

    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = filename;
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();

  }

}
