import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SelectedItemsConfiguration} from "./selected-items/selected-items.configuration";
import {ItemSelectionConfiguration} from "./item-selection/item-selection/item-selection.configuration";
import {ItemSelectionService} from "./item-selection/item-selection.service";


@Component({
  selector: 'app-order-handling-wizard-step2',
  templateUrl: './order-handling-wizard-step2.component.html',
  styleUrls: ['./order-handling-wizard-step2.component.scss']
})
export class OrderHandlingWizardStep2Component {

  @Input() viewModel: OrderHandlingWizardStep2ViewModel = OrderHandlingWizardStep2ViewModel.create();

  selectedItemsConfiguration: SelectedItemsConfiguration = SelectedItemsConfiguration
    .create()
    .setTableMode('itemhandling');

  itemSelectionConfiguration: ItemSelectionConfiguration = ItemSelectionConfiguration
    .create()
    .setSelectedItemsConfiguration(this.selectedItemsConfiguration);

  form: FormGroup;

  constructor(private formBuilder: FormBuilder, public itemSelectionService: ItemSelectionService) {

    this.form = this.formBuilder.group({});

  }

}

export class OrderHandlingWizardStep2ViewModel {

  private constructor(
    public handlingIsDeliveredState: boolean = false,
    public handlingIsDraftState: boolean = false,
    public handlingIsCheckInState: boolean = false
  ) {
  }

  public static create = () => new OrderHandlingWizardStep2ViewModel();

}
