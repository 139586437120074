import {Injectable} from '@angular/core';
import {BeaverItem} from "../../../_core/classes/beaver-item";
import {
    CtAttachmentManagerConfiguration,
    CtAttachmentManagerService, CtBinaryOperator,
    CTModelDatatableFilter
} from "@ctsolution/ct-framework";
import {ItemFile} from "../../../_core/classes/item-file";
import {ItemFileController} from "../../../_core/controllers/item-file.controller";

@Injectable()
export class ItemAttachmentsService {

    constructor(
        private itemFileController: ItemFileController,
        private attachmentManagerService: CtAttachmentManagerService) {
    }

    async open(item: BeaverItem) {

        const configuration: CtAttachmentManagerConfiguration<ItemFile> = CtAttachmentManagerConfiguration
            .create<ItemFile>()
            .setTitle(`Allegati ${item.Name}`)
            .setEmptyDataLabel('itemEmptyAttachmentLabel')
            .setNoteLabel('itemAttachmentNoteLabel')
            .enableOperations(true) // TODO: i file li devono poter caricare tutti?
            .setFileDownloader((file: ItemFile) => {

                this.itemFileController
                    .getItemFile(file, true)
                    ?.subscribe((blob: any) => {

                        if (blob) {

                            const fileURL = URL.createObjectURL(blob);
                            window.open(fileURL);

                        }

                    })

            })
            .setGetter(() => new Promise((resolve) => {

                const filter: CTModelDatatableFilter = CTModelDatatableFilter
                    .create()
                    .setField("Item.Oid")
                    .setValue(item.Oid)
                    .setOperatorType(CtBinaryOperator.Equal);

                this.itemFileController
                    .get([filter])
                    .then(result => resolve((result?.DataSource ?? []) as Array<ItemFile>))

            }))
            .setFileDeleteFunc((file: ItemFile) => this.itemFileController.delete(file))
            .setFileUploadFunc((file: File) => this.itemFileController.create(file, item))
            .setFileNameMapper((file: ItemFile) => file.Name)

        return this.attachmentManagerService
            .open<ItemFile>(configuration);

    }

}
