import {Component} from "@angular/core";
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelType
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {CatalogService} from "../catalog.service";
import {BeaverItem} from "../../../_core/classes/beaver-item";
import {ItemType} from "../../../_core/enum/item-type";
import {ItemSupplier} from "../../../_core/classes/item-supplier";
import {ItemSupplierRouteData} from "../../../_core/route-data/item-supplier.route-data";
import {ItemRouteData} from "../../../_core/route-data/item.route-data";

@Component({
  selector: "app-catalog-edit",
  template: `
      <ct-model *ngIf="itemModelConfiguration" [configuration]="itemModelConfiguration"></ct-model>

      <ng-container *ngIf="enableSupplierTable">

          <mat-divider></mat-divider>

          <h3> {{ 'supplierManagementLabel' | translate}} </h3>

          <ct-model *ngIf="suppliersModelConfiguration" [configuration]="suppliersModelConfiguration"></ct-model>

      </ng-container>`
})
export class CatalogEditComponent {

  itemModelRouteData: CtModelRouteData = ItemRouteData()
    .setModelType(CtModelType.FORM);
  itemModelConfiguration: CtModelConfiguration<BeaverItem> | null = null;

  supplierModelRouteData: CtModelRouteData = ItemSupplierRouteData()
    .setModelType(CtModelType.DATATABLE);
  suppliersModelConfiguration: CtModelConfiguration<CatalogEditComponent> | null = null;

  constructor(
    private route: ActivatedRoute,
    private catalogService: CatalogService,
    private onFlyCreateHelper: CtModelOnFlyCreateService) {

    this.setupItemModelConfiguration()

    this.setupSuppliersModelConfiguration();

  }

  get enableSupplierTable(): boolean {

    if (!this.itemModelConfiguration) return false;

    const typeFilter = this.itemModelConfiguration.filterValues?.find(flt => flt.Field === 'Type');

    return !!this.itemModelConfiguration.RouteData?.id && typeFilter?.Value === ItemType.Item;

  }

  setupItemModelConfiguration() {

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) this.itemModelRouteData.setId(+pId);

    const configuration = CtModelConfiguration
      .create<BeaverItem>()
      .setRouteData(this.itemModelRouteData);

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!typePathValue) return;

    const constantType: ItemType | null = this.catalogService.getCatalogTypeByRouteValue(typePathValue);

    if (constantType) {

      configuration
        .setFilterValues([
          CTModelDatatableFilter
            .create()
            .setField('Type')
            .setValue(constantType)
            .setOperatorType(CtBinaryOperator.Equal)
        ]);

      const isModuleTemplate: boolean = constantType === ItemType.Module;

      const fieldsCustomTemplates = [
        {prop: "Type", disabled: true, required: true, visible: false},
        {prop: "Suppliers", visible: false}, // useremo la tabella esterna per i fornitori
        {prop: "Category", visible: !isModuleTemplate},
        {prop: "Brand", visible: !isModuleTemplate},
        {
          prop: "DefaultUnitOfMeasure",
          disabled: !!configuration.RouteData?.id // per un articolo non devo piu poter modificare l'unità di misura una volta settato
        }
      ];

      if (isModuleTemplate) {

        this
          .route
          .data
          .subscribe(data => {
            data["title"] = "Assemblamenti";
          });

      }

      configuration
        .setFieldsCustomTemplates(fieldsCustomTemplates);

    }

    this.itemModelConfiguration = configuration;

  }

  setupSuppliersModelConfiguration() {

    this.suppliersModelConfiguration = CtModelConfiguration
      .create<CatalogEditComponent>()
      .setRouteData(this.supplierModelRouteData)
      .setFilterable(false)
      .setFieldsCustomTemplates([{prop: "Item", disabled: false}])
      .setFilterValues([
        CTModelDatatableFilter
          .create()
          .setField('Item.Oid')
          .setValue(this.itemModelConfiguration?.RouteData?.id)
          .setOperatorType(CtBinaryOperator.Equal)
      ])
      .setOnCreate(() => this.openItemSupplierForm())
      .setOnEdit((itemSupplier: ItemSupplier) => this.openItemSupplierForm(itemSupplier.Oid));

  }

  openItemSupplierForm(Oid: number | null = null) {

    const routeData: CtModelRouteData = CtModelRouteData
      .create()
      .setController(ItemSupplierRouteData().controller)
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create()


    if (Oid) {

      routeData
        .setId(Oid);

    }

    if (this.itemModelConfiguration?.DataSource) {

      configuration
        .setFilterValues([
          CTModelDatatableFilter
            .create()
            .setField('Item')
            .setValue(this.itemModelConfiguration?.DataSource)
            .setOperatorType(CtBinaryOperator.Equal)
        ]);

    }

    configuration
      .setRouteData(routeData)
      .setFieldsCustomTemplates([{prop: 'Item', disabled: true}])

    this.onFlyCreateHelper
      .openOnFlyCreate(configuration)
      .afterClosed()
      .subscribe((reload: any) => {

        if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });

  }

}
