<mat-card class="user-working-hours-pictures-container">

  <mat-card-header>

    <mat-card-title fxLayout="row">
      <div> {{"attachedPhotosLabel" | translate }}</div>
      <div fxFlex></div>
      <div>
        <button [mat-dialog-close]="true" mat-mini-fab color="accent">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>

    <mat-card-subtitle>{{ "exploreManageAttachedPhotosLabel" | translate }}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-actions align="end">

    <button mat-raised-button color="accent" (click)="fileUploader.click()"> {{"addPhotosLabel" | translate }}</button>

    <input #fileUploader hidden type="file" accept="image/*" (change)="onUpload($event)" multiple>

  </mat-card-actions>

  <mat-card-content>

    <div
      *ngIf="pictures.length; else emptyList"
      fxLayout="row wrap"
      class="user-working-hours-pictures-gallery">

      <div
        class="gallery-item"
        *ngFor="let picture of pictures; let i = index;"
        [class.selected]="selectedIndex === i"
        [fxFlex.xs]="selectedIndex === i ? 100 : 29"
        [fxFlex]="selectedIndex === i ? 100 : 31">

        <button
          mat-mini-fab
          color="primary"
          class="preview"
          (click)="selectPicture(i)">
          <mat-icon>{{ selectedIndex === i ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>

        <img [src]="picture.getPreviewUrl()">

        <button
          mat-mini-fab
          color="accent"
          class="delete"
          (click)="deletePicture(picture)">
          <mat-icon style="color: white">delete</mat-icon>
        </button>

      </div>

    </div>

    <ng-template #emptyList>
      <p style="text-align: center"> {{"noPhotosUploadedMessage" | translate}}</p>
    </ng-template>

  </mat-card-content>

</mat-card>
