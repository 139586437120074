import {CTBase} from "@ctsolution/ct-framework";
import {BeaverItem} from "./beaver-item";
import {Handling} from "./handling";
import {BeaverUM} from "./beaver-um";
import {BeaverItemStock} from "./beaver-item-stock";
import {HandlingStateEnum} from "../enum/handling-state.enum";
import {
  StatusToggleComponentConfiguration
} from "../../components/handling/order-handling-wizard/order-handling-wizard-components/status-toggle-component/status-toggle-component.configuration";
import {FormControl} from "@angular/forms";
import {StatusToggleComponentItemHadlingStates} from "../constants";

export class ItemHandling extends CTBase<ItemHandling> {

  Oid?: number;
  StartQty: number | null = 0;
  Qty: number | null = 0;
  Item: BeaverItem | null = null;
  Module: BeaverItem | null = null;
  Handling: Handling | null = null;
  UM: BeaverUM | null = null;
  ItemCategoryTrackEnable: boolean | null = null;
  State: HandlingStateEnum | null = null;
  Barcodes: string[] = [];
  UpdateAt: Date | null = null;
  PartialQty: number | null = 0;
  StatusToggleComponentConfiguration: StatusToggleComponentConfiguration<HandlingStateEnum>;
  BeaverItemStock: BeaverItemStock | null = null; // mi serve per tenermi in pancia lo stock

  private constructor();
  private constructor(model?: ItemHandling)
  private constructor(model?: ItemHandling) {

    super();

    if (model) this.getClass(model);

    this.StatusToggleComponentConfiguration = StatusToggleComponentConfiguration
      .create<HandlingStateEnum>()
      .setValues(StatusToggleComponentItemHadlingStates())
      .setDirection('col')
      .setControl(new FormControl(model?.State ?? null));

    if (model?.State === HandlingStateEnum.Delivered) {

      this.StatusToggleComponentConfiguration
        .control
        ?.disable();

    }

    this.setPartialQty(0);

  }

  public static create = (model?: ItemHandling): ItemHandling => new ItemHandling(model);

  setStartQty(value: number | null): ItemHandling {

    this.StartQty = value;
    return this;

  }

  setPartialQty(value: number | null): ItemHandling {

    this.PartialQty = value;
    return this;

  }

  setQty(value: number | null): ItemHandling {

    this.Qty = value;
    return this;

  }

  setItemFromBeaverItemStock(value: BeaverItemStock | null): ItemHandling {

    if (value) {

      this.Item = BeaverItem.createFromBeaverItemStock(value);
      this.BeaverItemStock = value;

    }

    this
      .setUMOid(value?.UMOid ?? -1)
      .setStartQty(value?.Stock ?? 0)
      .setPartialQty(value?.Stock ?? 0);

    if (value?.UMSymbol) {

      this.UM!.Symbol = value.UMSymbol;

    }

    return this;

  }

  setModule(value: BeaverItemStock | null): ItemHandling {

    if (value) {

      this.Module = BeaverItem.createFromBeaverItemStock(value);

    }

    return this;

  }

  setUMOid(Oid: number | null): ItemHandling {

    this.UM = {Oid} as BeaverUM;
    return this;

  }

  setState(value: HandlingStateEnum) {

    this.State = value;
    return this;

  }

}
