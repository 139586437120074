import {Component, Inject, Input, ViewChild} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {BeaverLocation} from "../../../../_core/classes/beaver-location";
import {MatLegacySelectionList} from "@angular/material/legacy-list";

@Component({
  selector: 'app-contact-address-list',
  templateUrl: './customer-address-list.component.html',
  styleUrls: ['./customer-address-list.component.scss']
})
export class CustomerAddressListComponent {

  @ViewChild(MatLegacySelectionList) list: MatLegacySelectionList | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any[],
    private dialogRef: MatDialogRef<CustomerAddressListComponent>) {
  }

  close() {

    this.dialogRef.close();

  }

  save() {

    if (!this.list?.selectedOptions.selected.length) return;

    this.dialogRef
      .close(this.list.selectedOptions.selected[0].value);

  }

  getFormattedAddress(value: any) {

    return `${value.Name}${value.City ? ` , ${value.City}` : ``}${value.PostalCode ? ` , ${value.PostalCode}` : ``}`;

  }

}
