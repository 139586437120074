<form
  style="margin-bottom: 15px"
  (keydown.enter)="onSearch()"
  [formGroup]="form">

  <ct-control [configuration]="itemSearchControl"></ct-control>

  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center">

    <ct-button [configuration]="importButton"></ct-button>

    <ct-button [configuration]="searchButton"></ct-button>

  </div>

</form>
