import {Component, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY} from "@ctsolution/ct-framework";
import {WarehouseService} from "../warehouse.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-warehouse-detail",
  templateUrl: "./warehouse-detail.component.html",
  styleUrls: ["./warehouse-detail.component.scss"]
})
export class WarehouseDetailComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;

  pId: string | null = null;
  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Nuova movimentazione")
    .setAction(() => this.newHandling())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private route: ActivatedRoute,
    private whService: WarehouseService,
    private translate: TranslateService,
    private router: Router) {

    this.pId = this.route.snapshot.paramMap.get("id");

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        this.route
          .data
          .subscribe(data => {

            if (qp["Name"]) {

              setTimeout(() => {

                this.translate
                  .get("detailOf", {name: qp["Name"]})
                  .subscribe(result => data['title'] = result);

              })

            }
            data['headerActions'] = this.headerActions;
            data['urls'] = [];

          })

      });

  }

  reportQR() {

    if (!this.pId) return;

    this.whService
      .openQRCodeFromLocation({locationOid: +this.pId, locationName: this.route.snapshot.data['title']});

  }

  reportPDF() {

    if (!this.pId) return;

    this.whService
      .downloadLocationPDF(+this.pId);

  }

  newHandling() {

    this.router
      .navigate(["/", "handling", "movement", "create"], {queryParams: {startFrom: this.pId}});

  }

}
