import {Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Observable} from "rxjs";
import {CtModelConfiguration} from "@ctsolution/ct-framework";

@Injectable({
    providedIn: "root"
})
export class ControllerHelper {

    constructor(private _webapi: CtWebapiService) {
    }

    list<T>(request: DataRequest): Promise<CtModelConfiguration<Array<T>>> {

        return new Promise<CtModelConfiguration<Array<T>>>((resolve) => {

            request
                .setAction("List")
                .setQueryParams({
                    hideSpinner: true
                });

            if (!request.body) {

                request.setBody({});

            }

            this._webapi
                .post(request)
                .subscribe((result: CtWebapiGenericResponse<CtModelConfiguration<Array<T>>>) => resolve(result?.Result as CtModelConfiguration<Array<T>>));

        });

    }

    getFile(request: DataRequest): Observable<Blob> {

        request
            .setResponseType("blob");

        if (!request.method) {

            request
                .setMethod(MethodEnum.GET)

        }

        return this._webapi
            .request(request);

    }

}

export interface DataSourceListElement {
    Name: string | null;
    Oid: number | null;
}
