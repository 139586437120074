import {Component} from '@angular/core';

@Component({
  selector: 'app-module-create-loading-panel',
  template: `
      <div class="loading">

          <p> {{ "ModuleCreateLoadingPanel.CheckingStockMessage" | translate }}</p>
          <p> {{ "pleaseWaitMessage" | translate }}</p>

          <mat-progress-bar mode="indeterminate"></mat-progress-bar>

      </div>
  `
})
export class ModuleCreateLoadingPanelComponent {
}
