import {MediaMatcher} from "@angular/cdk/layout";
import {Router} from "@angular/router";
import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild} from "@angular/core";

import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {TranslateService} from "@ngx-translate/core";
import {Direction} from "@angular/cdk/bidi";
import {MenuItems} from "./menu/menu-items";
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {MatSidenav} from "@angular/material/sidenav";
import {JwtService} from "../../_core/lib/jwt.service";
import {LangService} from "../../_core/lib/lang.service";
import {UserCompanySettings} from "../../_core/classes/user-company-settings";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-layouts",
  templateUrl: "full.component.html",
  styleUrls: []
})
export class FullComponent implements OnDestroy {

  @ViewChild('snav') snav?: MatSidenav;
  $email: any;

  mobileQuery: MediaQueryList;

  dir: Direction = "ltr";
  dark = false;
  minisidebar = false;
  boxed = false;

  sidebarOpened = false;
  status = false;

  selectedLanguage: any = null;

  public languages: any[] = [
    {
      language: "Italiano",
      code: "it",
      type: "IT",
      icon: "it"
    },
    {
      language: "English",
      code: "en",
      type: "US",
      icon: "us"
    }
  ];

  public config: PerfectScrollbarConfigInterface = {};
  // tslint:disable-next-line - Disables all
  private _mobileQueryListener: () => void;

  companySettings: UserCompanySettings | null = null;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public router: Router,
    public menuItems: MenuItems,
    private translate: TranslateService,
    private lang: LangService,
    private _jwt: JwtService,
    private _auth: CtAuthenticationService
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 1100px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.$email = this._jwt.getUserEmail();

    this.dark = false;

  }

  async ngAfterViewInit() {

    this.companySettings = await this._jwt.getUserCompanySettings();

    if (this.companySettings?.UserHasTranslationsEnabled) {

      this.setupCurrentLang();

    }

  }

  clickEvent(): void {

    this.status = !this.status;

  }

  darkClick() {

    const body = document.getElementsByTagName("body")[0];
    body.classList.toggle("dark");

  }

  setupCurrentLang(lang: string = this.lang.getCurrentLang(), forceUpdate = false) {

    this.selectedLanguage = this.languages.find(element => element.code === lang);

    if (forceUpdate) {

      this.lang
        .useLang(lang)
        .then()

    }

  }

  logout() {

    this._auth
      .signOut();

  }

  toggleSidebar() {

    if (this.mobileQuery.matches) {

      this.minisidebar = !this.minisidebar

    } else {

      this.snav?.toggle();

    }

  }

  ngOnDestroy(): void {

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

}
