<mat-nav-list appAccordion>
  <mat-list-item
    appAccordionLink
    [class]="'menuitem-' + menuitem.name"
    *ngFor="let menuitem of menuItems$ | async"
    routerLinkActive="selected"
    group="{{ menuitem.state }}"
  >
    <a
      class=""
      appAccordionToggle
      *ngIf="menuitem.type === 'link'"
      (click)="handleNotify(menuitem)"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
        }}</span>
    </a>

    <a class="" appAccordionToggle href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
        }}</span>
    </a>

    <a
      class=""
      appAccordionToggle
      href="{{ menuitem.state }}"
      target="_blank"
      *ngIf="menuitem.type === 'extTabLink'"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
        }}</span>
    </a>

    <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
        }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>

    <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
      <mat-list-item
        [class]="'menuitem-' + childitem.name"
        *ngFor="let childitem of menuitem.children; let j = index; let i = childitem"
        routerLinkActive="selected"
      >
        <a
          [routerLink]="['/', menuitem.state, childitem.state]"
          *ngIf="childitem.type === 'link'"
          class="relative"
          routerLinkActive="selected"
          (click)="itemSelect[i] = j"
          (click)="handleNotify()"
        >{{ ('CT_MENU.' + childitem.name) | translate }}</a
        >
      </mat-list-item>

      <mat-list-item
        [class]="'menuitem-' + childitem.name"
        *ngFor="let childitem of menuitem.children; let j = index; let i = childitem">
        <a
          class=""
          href="javascript: void(0);"
          *ngIf="childitem.type === 'subchild'"
          (click)="addExpandClass(childitem.name)"
          [ngClass]="{ selected: showMenu === childitem.name }"
        >
          <span>{{ ('CT_MENU.' + childitem.name) | translate }}</span>
          <span fxFlex></span>
          <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>

        <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
          <mat-list-item [class]="'menuitem-' + child.name" *ngFor="let child of childitem.subchildren"
                         routerLinkActive="selected">
            <a
              [routerLink]="['/', menuitem.state, childitem.state, child.state]"
              routerLinkActive="selected"
            >{{ ('CT_MENU.' + child.name) | translate }}</a
            >
          </mat-list-item>
        </mat-nav-list>
      </mat-list-item>
    </mat-nav-list>

    <div class="separator text-muted" *ngIf="menuitem.type === 'separator'">
      <span>{{ ('CT_MENU.' + menuitem.name) | translate }}</span>
    </div>
  </mat-list-item>
</mat-nav-list>
