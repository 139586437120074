<h1 mat-dialog-title> {{ "selectAddressLabel" | translate }}</h1>
<div mat-dialog-content>

  <mat-selection-list [multiple]="false" #address>
    <mat-list-option *ngFor="let item of data" [value]="getFormattedAddress(item)">
      {{ getFormattedAddress(item) }}
    </mat-list-option>
  </mat-selection-list>

</div>
<div
  mat-dialog-actions
  fxLayout="row wrap"
  fxLayoutAlign="space-between center">
  <button mat-button color="warn" (click)="close()">{{ "CT_PAGE.CT_FORM.cancel" | translate }}</button>
  <button mat-button color="primary" (click)="save()" cdkFocusInitial> {{ "CT_PAGE.CT_FORM.save" | translate }}</button>
</div>
