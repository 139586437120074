import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator,
  CtDatatableConfiguration,
  CtRangeControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {
  StatusToggleComponentButtonConfiguration,
  StatusToggleComponentConfiguration
} from "../components/handling/order-handling-wizard/order-handling-wizard-components/status-toggle-component/status-toggle-component.configuration";
import {HandlingStateEnum} from "./enum/handling-state.enum";

export const DEFAULT_DATATABLE_CONFIGURATION = (): CtDatatableConfiguration => CtDatatableConfiguration.create()
  .setScrollbarH(true)
  .setRowHeight(60)
  .setHeaderHeight(60)
  .setInHeaderFiltersEnabled(true);

export const QtaControl = (name: string): CtControlConfiguration => CtControlConfiguration.create()
  .setName(name)
  .setTheme(CtThemeTypes.MATERIAL)
  .setType(CtControlTypes.COUNTER)
  .setValue(1)
  .setOptions(
    CtRangeControlOptions
      .create()
      .setMin(1))
  .setValidators([CtControlValidator.create({name: "required"} as CtControlValidator)])
  .setLabel("quantity");

export const StatusToggleComponentItemHadlingDeliveredState: () => StatusToggleComponentButtonConfiguration<HandlingStateEnum>
  = () => StatusToggleComponentButtonConfiguration
  .create<HandlingStateEnum>(HandlingStateEnum.Delivered)
  .setLabel('handlingStates.Delivered')
  .setIcon('check_circle');
export const StatusToggleComponentItemHadlingPartialState: () => StatusToggleComponentButtonConfiguration<HandlingStateEnum>
  = () => StatusToggleComponentButtonConfiguration
  .create<HandlingStateEnum>(HandlingStateEnum.Partial)
  .setLabel('handlingStates.Partial')
  .setIcon('app_registration');

export const StatusToggleComponentHadlingStates: () => StatusToggleComponentButtonConfiguration<HandlingStateEnum>[]
  = () => [
  StatusToggleComponentButtonConfiguration
    .create<HandlingStateEnum>(HandlingStateEnum.Draft)
    .setLabel('Preparing')
    .setIcon('inventory'),
  StatusToggleComponentButtonConfiguration
    .create<HandlingStateEnum>(HandlingStateEnum.OnTheWay)
    .setLabel('handlingStates.OnTheWay')
    .setIcon('local_shipping'),
  StatusToggleComponentButtonConfiguration
    .create<HandlingStateEnum>(HandlingStateEnum.CheckIn)
    .setLabel('handlingStates.CheckIn')
    .setIcon('edit_note'),
  StatusToggleComponentItemHadlingDeliveredState()
]

export const StatusToggleComponentItemHadlingStates: () => StatusToggleComponentButtonConfiguration<HandlingStateEnum>[]
  = () => [
  StatusToggleComponentItemHadlingPartialState(),
  StatusToggleComponentItemHadlingDeliveredState()]
