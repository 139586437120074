import {
  CtButtonConfiguration,
  CtCardConfiguration,
  CtCardIconConfiguration,
  CtCardIconPosition
} from "@ctsolution/ct-framework";

//# region BIG CARDS
export const NEW_REGISTER_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.newHourlyRecordLabel")
  .setDescription("dashboard.enterHoursToTrackActivitiesLabel")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setIcon("alarm_on")
      .setClass("bg-beaver-alt text-white")
      .setPosition(CtCardIconPosition.SUFFIX))
  .setClass("bg-beaver-alt text-white")
  .setButton(CtButtonConfiguration.create().setIcon("arrow_forward"));
//# endregion BIG CARDS

//# region CARDS
export const REGISTER_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.hourlyRecordLabel")
  .setDescription("dashboard.viewCompleteHourlyRecordLabel")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-info text-white")
      .setIcon("build"));
export const WORK_ORDER_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("WorkOrderWidget.title")
  .setDescription("WorkOrderWidget.description")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-danger text-white")
      .setIcon("exposure_plus_1"));
//# endregion CARDS
