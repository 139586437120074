import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ModuleCreateComponent} from "./module-create.component";
import {ModuleCreateService} from "./module-create.service";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {CtButtonModule, CtControlModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {ReactiveFormsModule} from "@angular/forms";
import {ModuleCreateLoadingPanelComponent} from './module-create-loading-panel/module-create-loading-panel.component';
import {ModuleCreateFormComponent} from './module-create-form/module-create-form.component';
import {ModuleCreateHandlingsComponent} from './module-create-handlings/module-create-handlings.component';
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatIconModule} from "@angular/material/icon";
import {ModuleItemOrderComponent} from "./module-create-handlings/module-item-order/module-item-order.component";

@NgModule({
  declarations: [
    ModuleCreateComponent,
    ModuleItemOrderComponent,
    ModuleCreateLoadingPanelComponent,
    ModuleCreateFormComponent,
    ModuleCreateHandlingsComponent
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatProgressBarModule,
        CtButtonModule,
        CtControlModule,
        ReactiveFormsModule,
        MatListModule,
        MatIconModule,
        CtFrameworkModule
    ],
  providers: [
    ModuleCreateService
  ]
})
export class ModuleCreateModule {
}
