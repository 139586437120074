import {Component} from '@angular/core';
import {
  CtControlConfiguration,
  CTFieldInfos,
  CtFormContainerHelper,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";

const UserWorkingHoursDictionaryInfoControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "DictionaryInfo",
  Type: "UserWorkingHoursDictionaryInfoDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/UserWorkingHoursDictionaryInfo/Lookup`,
    CodeField: "Oid",
    DescriptionField: "Name",
    IncrementalSearch: false,
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-dictionary-info-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursDictionaryInfoControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursDictionaryInfoControl))
    .setTheme(CtThemeTypes.MATERIAL);

}
