<form [formGroup]="form">

  <mat-card>

    <mat-card-header>
      <mat-card-title>{{ 'itemManagement' | translate }}</mat-card-title>
      <mat-card-subtitle [innerHTML]="'itemManagement.description' | translate "></mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

      <app-item-search
        *ngIf="viewModel.handlingIsDraftState"
        [configuration]="itemSelectionConfiguration"
        (onSelected)="itemSelectionService.updateSelection($event, selectedItemsConfiguration)"
        (onImport)="selectedItemsConfiguration.setSelected($event)"></app-item-search>

      <app-selected-items
        *ngIf="(selectedItemsConfiguration?.itemHandlings?.length ?? 0) > 0"
        [viewModel]="viewModel"
        [configuration]="selectedItemsConfiguration"></app-selected-items>

    </mat-card-content>

  </mat-card>

</form>
