import {DataRequest} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {ControllerHelper} from "./controller.helper";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {FileService} from "../lib/file.service";

const CONTROLLER: string[] = ["report"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable({
  providedIn: 'root'
})
export class ReportController {

  constructor(private _helper: ControllerHelper, private fileService: FileService) {
  }

  getLocationQR(locationOid: number) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("LocationQR")
      .setQueryParams({locationOid, hideSpinner: true});

    return this._helper
      .getFile(request);

  }

  getLocationPDF(locationOid: number, directDownload = true) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("LocationPdf")
      .setQueryParams({locationOid, hideSpinner: true});

    return this._helper
      .getFile(request)
      .pipe(
        map(result => {

          if (directDownload) {

            this.fileService.blobDownload(result, `${environment.projectName}_deposit${locationOid}`);

          }

        })
      );

  }

  getHandlingPDF(handlingOid: number, directDownload = true) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("HandlingPdf")
      .setQueryParams({handlingOid, hideSpinner: true});

    return this._helper
      .getFile(request)
      .pipe(
        map(result => {

          if (directDownload) {

            this.fileService.blobDownload(result, `${environment.projectName}_handling${handlingOid}`);

          }

        })
      );

  }

}
