import {Component} from '@angular/core';
import {CtButtonConfiguration, MAT_RAISED_WARN} from "@ctsolution/ct-framework";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-supplier-change-confirm',
  template: `
    <h1 mat-dialog-title> {{ "warning" | translate }} </h1>
    <div mat-dialog-content>
      <p> {{ "confirmSupplierChangeMessage" | translate }}</p>
    </div>
    <div mat-dialog-actions style="display: flex; justify-content: space-between;">
      <ct-button [configuration]="cancelButton"></ct-button>
      <ct-button [configuration]="submitButton"></ct-button>
    </div>
  `
})
export class SupplierChangeConfirmComponent {

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.dialogRef.close(false))
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("proceedLabel")
    .setAction(() => this.dialogRef.close(true))
    .setMatherialOptions(MAT_RAISED_WARN);

  constructor(private dialogRef: MatLegacyDialogRef<SupplierChangeConfirmComponent>) {
  }

}
