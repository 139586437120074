import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CatalogListComponent} from "./catalog-list.component";
import {CtAttachmentManagerModule, CtModelModule} from "@ctsolution/ct-framework";
import {ItemFileController} from "../../../_core/controllers/item-file.controller";
import {ItemAttachmentsService} from "./item-attachments.service";

@NgModule({
    declarations: [
        CatalogListComponent
    ],
    imports: [
        CommonModule,
        CtModelModule,
        CtAttachmentManagerModule
    ],
    providers: [
        ItemFileController,
        ItemAttachmentsService
    ]
})
export class CatalogListModule {
}
