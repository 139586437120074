import {Component} from "@angular/core";
import {
  CtBinaryOperator,
  CtDatatableActionConfiguration,
  CtDatatableConfiguration,
  CtModelConfiguration, CTModelDatatableFilter,
  CtModelOperator
} from "@ctsolution/ct-framework";
import {HandlingService} from "../handling.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {Handling} from "../../../_core/classes/handling";
import {HandlingOriginEnum} from "../../../_core/enum/handling-origin.enum";
import {HandlingRouteData} from "../../../_core/route-data/handling.route-data";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-handling-create-list",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class HandlingListComponent {

  configuration: CtModelConfiguration<HandlingListComponent> | null = null;

  downloadPdfCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
    .setColor("primary")
    .setIcon("picture_as_pdf");
  datatableConfiguration: CtDatatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
    .setCustomDatatableAction(this.downloadPdfCTA, (event => this.handlingService.downloadHandlingPDF(event.Oid)));

  constructor(
    private handlingService: HandlingService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService) {
  }

  ngAfterViewInit() {

    this.constantOrigin()
      .then(origin => {

        this.configuration = CtModelConfiguration
          .create<HandlingListComponent>()
          .setOperations([CtModelOperator.Create, CtModelOperator.Update])
          .setRouteData(HandlingRouteData())
          .setOnEdit((event: Handling) => this.router.navigate(["..", "edit", event.Oid!.toString()], {relativeTo: this.route}))
          .setFieldsCustomTemplates([
            {
              prop: (origin['HandlingOrigin'] === HandlingOriginEnum.Supplier ? "LocationFrom" : "SupplierFrom"),
              visible: false
            }
          ])
          .setCTDatatableConfiguration(this.datatableConfiguration)
          .setFilterValues([
            CTModelDatatableFilter
              .create()
              .setField('HandlingOrigin')
              .setValue(origin['HandlingOrigin'])
              .setOperatorType(CtBinaryOperator.Equal)
          ]);

      });

  }

  constantOrigin(): Promise<{ [p: string]: any }> {

    return new Promise<{ [p: string]: any }>((resolve) => {

      const originPathValue: string | null = this.route.snapshot.paramMap.get("origin");
      const HandlingOrigin: HandlingOriginEnum = this.handlingService.getHandlingTypeByRouteValue(originPathValue);

      this.route
        .data
        .subscribe(data => {

          switch (HandlingOrigin) {

            case HandlingOriginEnum.Supplier:
              data['title'] = "CT_MENU.order";
              break;
            case HandlingOriginEnum.Location:
            default:
              data['title'] = "CT_MENU.handling";
              break;
          }

          resolve({HandlingOrigin});

        });

    });

  }

}
