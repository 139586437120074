import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {WarehouseDetailComponent} from "./warehouse-detail.component";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {WarehouseHandlingsModule} from "./warehouse-handlings/warehouse-handlings.module";
import {WarehouseStocksModule} from "./warehouse-stocks/warehouse-stocks.module";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {WarehouseQrModule} from "./warehouse-qr/warehouse-qr.module";

@NgModule({
  declarations: [
    WarehouseDetailComponent
  ],
    imports: [
        CommonModule,
        MatTabsModule,
        WarehouseHandlingsModule,
        WarehouseStocksModule,
        MatButtonModule,
        MatListModule,
        CtButtonModule,
        MatIconModule,
        WarehouseQrModule,
        CtFrameworkModule
    ]
})
export class WarehouseDetailModule {
}
