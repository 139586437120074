import {BeaverItem} from "./beaver-item";

export class ItemFile {

  Oid: number | null = null;
  Name: string | null = null;
  CreateAt: Date | null = null;

  constructor(
    public File: File | null = null,
    public Item: BeaverItem | null = null
  ) {

    if (File) {

      this.Name = File.name;

    }

  }

}
