import {Component} from "@angular/core";
import {
  CtBinaryOperator, CtDatatableActionConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator, CtSortOrderDescriptorParameter
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {CatalogService} from "../catalog.service";
import {GeneralService} from "../../../_core/lib/general.service";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {ItemType} from "../../../_core/enum/item-type";
import {ItemRouteData} from "../../../_core/route-data/item.route-data";
import {BeaverItem} from "../../../_core/classes/beaver-item";
import {ItemAttachmentsService} from "./item-attachments.service";

@Component({
  selector: "app-catalog-list",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`,
  styles: [`
    ::ng-deep {
      ct-datatable-action.no-data {
        button {
          color: #bdbfba !important;
        }
      }
    }`]
})
export class CatalogListComponent {

  configuration: CtModelConfiguration<CatalogListComponent> | null = null;

  fileHandlingCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
    .setColor("primary")
    .setIcon("attachment")
    .setDynamicClass(value => !value['FilesAttachedCount'] ? `no-data` : null)

  datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
    .setCustomDatatableAction(this.fileHandlingCTA,
      (async (event: BeaverItem) => {

        const ref = await this.itemAttachments.open(event)

        ref
          .afterClosed()
          .subscribe(result => {

            (event as any)['FilesAttachedCount'] = result ?? 0

          })

      }));

  constructor(
    private general: GeneralService,
    private route: ActivatedRoute,
    private itemAttachments: ItemAttachmentsService,
    private catalogService: CatalogService) {

    const configuration = CtModelConfiguration
      .create<CatalogListComponent>()
      .setRouteData(ItemRouteData())
      .setOrderValues(
        new Array<CtSortOrderDescriptorParameter>(
          new CtSortOrderDescriptorParameter()
            .setField('Name')
            .setIsDescending(false)
        ))
      .setCTDatatableConfiguration(this.datatableConfiguration)
      .setFieldsCustomTemplates([{prop: "Type", visible: false}])

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!typePathValue) return;

    const constantType: ItemType | null = this.catalogService.getCatalogTypeByRouteValue(typePathValue);

    if (constantType) {

      configuration
        .setFilterValues([
          CTModelDatatableFilter
            .create()
            .setField('Type')
            .setValue(constantType)
            .setOperatorType(CtBinaryOperator.Equal)
        ]);

      if (constantType === ItemType.Module) {

        configuration
          .setOperations([CtModelOperator.Detail])
          .setOnDetail(event => this.general.navigateOnCTModelRouter(["catalog", typePathValue], "detail", {
            value: event,
            configuration: this.configuration!,
            qpKeys: ["Name"]
          }));

        this
          .route
          .data
          .subscribe(data => {
            data["title"] = "CT_MENU.module";
          });

      }

    }

    configuration
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["catalog", typePathValue], "create"))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["catalog", typePathValue], "edit", {
        value: event,
        configuration: this.configuration!
      }));

    this.configuration = configuration;

  }

}
