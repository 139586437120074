import {Component, Input} from '@angular/core';
import {CtControlConfiguration, CtSelectControlValue, CtThemeTypes} from "@ctsolution/ct-framework";
import {
  LocationToControl
} from "../../../../handling/order-handling-wizard/order-handling-wizard-step1/_forms/location-to.form-configuration";
import {FormBuilder, FormGroup} from "@angular/forms";
import {LocationController} from "../../../../../_core/controllers/location.controller";
import {ModuleCreationCheckResult} from "../../../../../_core/controllers/item.controller";
import {BeaverLocation} from "../../../../../_core/classes/beaver-location";
import {DataSourceListElement} from "../../../../../_core/controllers/controller.helper";
import {QtaControl} from "../../../../../_core/constants";


@Component({
  selector: 'app-module-create-form',
  templateUrl: './module-create-form.component.html',
  styleUrls: ['./module-create-form.component.scss'],
  providers: [LocationController]
})
export class ModuleCreateFormComponent {

  @Input() model: ModuleCreationCheckResult | null = null;
  qtyControl: CtControlConfiguration = QtaControl("qty")
    .setLabel("quantity");
  locationToControl: CtControlConfiguration = CtControlConfiguration
    .create(LocationToControl)
    .setTheme(CtThemeTypes.MATERIAL);
  form: FormGroup;

  constructor(
    private locationController: LocationController,
    private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

    this.qtyControl
      .setFormParent(this.form);

    this.locationToControl
      .setFormParent(this.form);

  }

  ngOnInit() {

    this.setupLocation();

  }

  ngAfterViewInit() {

    if (this.model?.PossibileModuleQty) {

      this.qtyControl
        .setValue(this.model.PossibileModuleQty);

    }

  }

  setupLocation() {

    this.locationController
      .getEnabledLocations()
      .then(enabledLocations => {

        //filtro le location di carico abilitate
        const loadLocations: Array<BeaverLocation> = enabledLocations.filter(elm => elm.LoadPermitted);

        this.locationToControl
          .setValueOptions(this.getCtSelectControlValue(loadLocations));

      });

  }

  private getCtSelectControlValue = (list: Array<DataSourceListElement | BeaverLocation>): CtSelectControlValue[] => list.map((elm) => CtSelectControlValue.create()
    .setLabel(elm.Name)
    .setValue(elm.Oid?.toString()));

}
