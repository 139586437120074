import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserWorkingHoursPicturesComponent} from './user-working-hours-pictures.component';
import {UserWorkingHoursPicturesService} from "./user-working-hours-pictures.service";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {MatIconModule} from "@angular/material/icon";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {CtButtonModule, CtFrameworkModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";

@NgModule({
  declarations: [
    UserWorkingHoursPicturesComponent
  ],
    imports: [
        CommonModule,
        MatLegacyCardModule,
        MatIconModule,
        FlexLayoutModule,
        MatLegacyButtonModule,
        CtButtonModule,
        CtSkeletonModule,
        MatLegacyDialogModule,
        CtFrameworkModule
    ],
  providers: [
    UserWorkingHoursPicturesService
  ]
})
export class UserWorkingHoursPicturesModule {
}
