import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import {RouterModule} from "@angular/router";
import {HandlingListComponent} from "./handling-list/handling-list.component";
import {HandlingListModule} from "./handling-list/handling-list.module";
import {UnitsOfMeasurementController} from "../../_core/controllers/unitsofmeasurements.controller";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyCheckboxModule} from "@angular/material/legacy-checkbox";
import {FormsModule} from "@angular/forms";
import {
  SupplierChangeConfirmModule
} from "./order-handling-wizard/order-handling-wizard-components/supplier-change-confirm/supplier-change-confirm.module";
import {
  AccessDeniedDialogModule
} from "./order-handling-wizard/order-handling-wizard-components/access-denied-dialog/access-denied-dialog.module";

@NgModule({
  imports: [
    CommonModule,
    MatDividerModule,
    HandlingListModule,
    AccessDeniedDialogModule,
    SupplierChangeConfirmModule,
    RouterModule.forChild([
      {
        path: ":origin",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            loadChildren: () => import('../handling/order-handling-wizard/order-handling-wizard.module').then(m => m.OrderHandlingWizardModule)
          },
          {
            path: "edit/:id",
            loadChildren: () => import('../handling/order-handling-wizard/order-handling-wizard.module').then(m => m.OrderHandlingWizardModule)
          },
          {
            path: "list",
            component: HandlingListComponent
          }
        ]
      }
    ]),
    CtButtonModule,
    MatLegacyDialogModule,
    MatLegacyCheckboxModule,
    FormsModule,
    CtFrameworkModule
  ],
  providers: [
    UnitsOfMeasurementController
  ]
})
export class HandlingModule {
}
