import {BeaverItemStock} from "../../../../../_core/classes/beaver-item-stock";
import {ItemHandling} from "../../../../../_core/classes/item-handling";
import {HandlingStateEnum} from "../../../../../_core/enum/handling-state.enum";


export class SelectedItemsConfiguration {

  get tableMode(): "standard" | "itemhandling" {
    return this._tableMode;
  }

  private set tableMode(value: "standard" | "itemhandling") {
    this._tableMode = value;
  }

  get editModeEnabled(): boolean {
    return this._editModeEnabled;
  }

  private set editModeEnabled(value: boolean) {
    this._editModeEnabled = value;
  }

  get itemHandlings(): ItemHandling[] {
    return this._itemHandlings;
  }

  private set itemHandlings(value: ItemHandling[]) {
    this._itemHandlings = value;
  }

  private _itemHandlings: ItemHandling[] = [];
  private _editModeEnabled = false;
  private _tableMode: 'standard' | 'itemhandling' = 'standard'

  get isItemHandlingTableMode() {

    return this.isMode('itemhandling');

  }

  private isMode(value: 'standard' | 'itemhandling') {

    return this.tableMode === value;

  }

  private constructor() {
  }

  public static create = (): SelectedItemsConfiguration => new SelectedItemsConfiguration();

  selectItems(value: BeaverItemStock[]): SelectedItemsConfiguration {

    const existingItemOids = this.itemHandlings.map(itemHandling => itemHandling.Item?.Oid);

    const values: ItemHandling[] = value
      .filter(item => !existingItemOids.includes(item.ItemOid))
      .map((elm: BeaverItemStock) => ItemHandling.create().setItemFromBeaverItemStock(elm));

    return this.updateSelected(values);

  }

  setTableMode(value: 'standard' | 'itemhandling') {

    this.tableMode = value;
    return this;

  }

  setSelected(value: ItemHandling[]): SelectedItemsConfiguration {

    this.itemHandlings = value.map(elm => ItemHandling.create(elm));
    return this;

  }

  updateSelected(value: ItemHandling[]): SelectedItemsConfiguration {

    this.itemHandlings = [...(this.itemHandlings ?? []), ...value];
    return this;

  }

  setEditModeEnabled(value: boolean) {

    this.editModeEnabled = value;
    return this;

  }

}
