import {DataSourceListElement} from "../controllers/controller.helper";

export class BeaverLocation implements DataSourceListElement {

  IsEnabled: boolean | null = null;
  LoadPermitted: boolean | null = null;
  UnloadPermitted: boolean | null = null;
  Name: string | null = null;
  Oid: number | null = null;
  WorkOrder: DataSourceListElement | null = null;
  Address: string | null = null;

}
