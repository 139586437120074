import {CTBase} from "@ctsolution/ct-framework";
import {UnitsOfMeasurements} from "./units-of-measurements";
import {BeaverItemStock} from "./beaver-item-stock";

export class BeaverItem extends CTBase<BeaverItem> {

  Oid: number | null = null;
  Name: string | null = null;
  Code: string | null = null;
  DefaultUnitOfMeasure: UnitsOfMeasurements | null = null;

  constructor();
  constructor(model?: BeaverItem)
  constructor(model?: BeaverItem) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: BeaverItem): BeaverItem => new BeaverItem(model);

  public static createFromBeaverItemStock = (model?: BeaverItemStock): BeaverItem => {

    return new BeaverItem({
      Oid: model?.ItemOid,
      Name: model?.ItemName,
      Code: model?.ItemCode,
      DefaultUnitOfMeasure: model?.UMOid
    } as BeaverItem)

  };

}
