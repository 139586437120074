import {Injectable} from "@angular/core";
import {CtSerializerHelperService, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {ControllerHelper} from "./controller.helper";
import {CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {ItemFile} from "../classes/item-file";
import {BeaverItem} from "../classes/beaver-item";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";

const CONTROLLER: string[] = ["itemfile"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class ItemFileController {

    constructor(
        private _webapi: CtWebapiService,
        private _helper: ControllerHelper,
        private serializerHelperService: CtSerializerHelperService) {
    }

    get = <T>(filters: CTModelDatatableFilter[]): Promise<CtModelConfiguration<Array<ItemFile>>> => this._helper.list(CONTROLLER_REQUEST()
        .setBody({
            Filters: filters
        }));

    create(file: File, item: BeaverItem) {

        const parameter: ItemFile = new ItemFile(file, item);

        const request: DataRequest = CONTROLLER_REQUEST()
            .setAction('Create')
            .setBody(this.serializerHelperService.objectToFormData(parameter))
            .setMethod(MethodEnum.POST);

        return this._webapi
            .request(request);

    }

    getItemFile(file: ItemFile, directDownload = false) {

        if (!file.Oid) return;

        const request: DataRequest = new DataRequest()
            .setController(['ct', 'File'])
            .setAction('Get')
            .setQueryParams({id: file.Oid, hideSpinner: true});

        return this._helper
            .getFile(request)
            .pipe(
                map(result => {

                    if (result) {

                        if (!file.Name) file.Name = `attachment`;

                        const fileName: string = file.Name.substring(0, file.Name.lastIndexOf('.')) || file.Name;

                        if (directDownload) {

                            this.blobDownload(result, `ICOMM_${fileName}`);

                        }

                    }

                    return result;

                })
            );

    }

    delete(file: ItemFile) {

        if (!file.Oid) return null;

        const request: DataRequest = new DataRequest()
            .setController([...CONTROLLER, 'DeleteByOid'])
            .setAction(file.Oid.toString())
            .setMethod(MethodEnum.DELETE);

        return this._webapi
            .request(request);

    }

    blobDownload(blob: Blob, filename = `${environment.projectName}_${new Date().getTime()}`) {

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        document.body.appendChild(a);

        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = filename;
        a.click();

        window.URL.revokeObjectURL(url);
        a.remove();

    }

}
