import {Injectable} from '@angular/core';
import {AccessDeniedDialogComponent} from "./access-denied-dialog.component";
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {CtAuthenticationGuard} from "@ctsolution/ct-authentication";

@Injectable({
  providedIn: 'root'
})
export class AccessDeniedDialogService {

  constructor(private dialog: MatLegacyDialog, private authenticationGuard: CtAuthenticationGuard) {
  }

  handleAdminAccess() {

    const isAdmin = this.authenticationGuard.isAdministrator();

    if (!isAdmin) {
      this.dialog.open(AccessDeniedDialogComponent, {maxWidth: '500px'});
    }

    return isAdmin; // Restituisce true se l'utente è un amministratore, altrimenti false
  }

}
