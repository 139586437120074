import {CTBase} from "@ctsolution/ct-framework";

export class UserCompanySettings extends CTBase<UserCompanySettings> {

  UserHasWarehouseManagementEnabled: boolean | null = null;
  UserHasMonitoringManagementEnabled: boolean | null = null;
  UserHasNotesEnabled: boolean | null = null;
  UserHasTranslationsEnabled: boolean | null = null;

  constructor();
  constructor(model?: UserCompanySettings)
  constructor(model?: UserCompanySettings) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: UserCompanySettings): UserCompanySettings => new UserCompanySettings(model);

}
