<div class="module-create-container">

  <h1 mat-dialog-title> {{ "moduleGenerationLabel" | translate}} </h1>

  <div mat-dialog-content>

    <app-module-create-loading-panel
      *ngIf="loadingData; else generator"></app-module-create-loading-panel>
    <ng-template #generator>

      <app-module-create-form
        #ModuleCreateForm
        [hidden]="!!moduleCreationRequestResult"
        [model]="moduleCreationCheckResult"></app-module-create-form>

      <app-module-create-handlings
        [hidden]="!moduleCreationRequestResult"
        [model]="moduleCreationRequestResult"></app-module-create-handlings>

    </ng-template>

  </div>

  <div mat-dialog-actions>

    <ct-button [configuration]="cancelButton"></ct-button>

    <ng-container *ngIf="!!moduleCreationCheckResult">

      <ct-button
        *ngIf="!!moduleCreationRequestResult; else generateHandlingsPreview"
        [configuration]="finalizeButton"></ct-button>
      <ng-template #generateHandlingsPreview>

        <ct-button [configuration]="previewButton"></ct-button>

      </ng-template>

    </ng-container>

  </div>

</div>
