import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";

const selectedLangKey = 'selectedLang';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(private http: HttpClient, private translate: TranslateService) {

    this.translate.setDefaultLang('en');

  }

  getCurrentLang(): string {

    const currentLang = localStorage.getItem(selectedLangKey);

    if (!currentLang || currentLang === 'undefined') return this.translate.getBrowserLang() ?? environment.defaultLang;

    return currentLang;

  }

  useLang(lang: string = this.getCurrentLang()): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      try {

        this.translate
          .use(lang)
          .subscribe(async () => {

            if ((<any>environment).customTranslationsDirectory) {

              const customTranslations = await this.loadCustomTranslations(`${(<any>environment).customTranslationsDirectory}/${lang}.json`);

              if (customTranslations) {

                this.translate.setTranslation(lang, customTranslations, true);

              }

            }

            localStorage.setItem(selectedLangKey, lang);
            resolve();

          });

      } catch (error) {
        reject(error);
      }

    });

  }

  private async loadCustomTranslations(path: string): Promise<any> {

    // Carica il file it.json
    return this.http
      .get<any>(path)
      .toPromise()
      .catch(() => ({})); // Se il file non esiste o ci sono errori nel caricamento, restituisci un oggetto vuoto

  }

}
