import {Component, Input} from "@angular/core";
import {CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../../_core/constants";
import {HandlingRouteData} from "../../../../_core/route-data/handling.route-data";

@Component({
    selector: "app-module-handlings",
    template: `
        <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class ModuleHandlingsComponent {

    @Input() referenceId: string | null = null;
    configuration: CtModelConfiguration<ModuleHandlingsComponent> | null = null;

    ngAfterViewInit() {

        if (!this.referenceId) return;

        this.configuration = CtModelConfiguration
            .create<ModuleHandlingsComponent>()
            .setRouteData(HandlingRouteData())
            .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
            .setFilterValues([
                CTModelDatatableFilter
                    .create()
                    .setField("ItemHandlingOid")
                    .setValue(this.referenceId)
                    .setOperatorType(CtBinaryOperator.Equal)
            ])
        // .setFieldsCustomTemplates([{prop: "GroupCode", visible: false}])

    }

}
