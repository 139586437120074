<div class="item-selection-container">

  <h1 mat-dialog-title> {{ "itemSelect" | translate }} </h1>

  <div mat-dialog-content>

    <ct-datatable
      [configuration]="list"
      (reload)="fetch($event)"></ct-datatable>

  </div>

  <div mat-dialog-actions>

    <ct-button [configuration]="cancelButton"></ct-button>

    <ct-button [configuration]="submitButton"></ct-button>

  </div>

</div>

