import {Component} from "@angular/core";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {FileController} from "../../../../../../_core/controllers/file.controller";
import {BeaverImportConfiguration} from "../../../../../../layouts/beaver-import/beaver-import.configuration";

@Component({
  selector: "app-item-selection-import",
  template: `
      <div class="item-selection-import-panel" mat-dialog-content>
          <beaver-import (close)="dialogRef.close()" [configuration]="configuration"></beaver-import>
      </div>`,
  providers: [FileController],
  styles: [
    `.item-selection-import-panel {
      margin: 0px;
      padding: 0px;
    }`
  ]
})
export class ItemSelectionImportComponent {

  configuration: BeaverImportConfiguration = BeaverImportConfiguration
    .create()
    .setImportSubscription((file: File) => this.fileController.handlingItemQuantityImport(file))
    .setImportSubscriptionCallBack(<ItemHandling>(response: CtWebapiGenericResponse<ItemHandling>) => {

      this.dialogRef
        .close(response.Result);

    })
    .setTitle("itemSelectionImport.title")
    .setSubtitle("itemSelectionImport.subTitle")
    .setDescription("")
    .setTemplate({
      path: `assets/templates/BVSItemHandlingImportTemplate.csv`,
      fileName: `BVSItemHandlingImportTemplate${new Date().getFullYear()}`
    })
    .enableCloseAction(true);

  constructor(private fileController: FileController, public dialogRef: MatDialogRef<ItemSelectionImportComponent>) {
  }

}
