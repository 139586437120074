import {Component, OnInit} from '@angular/core';
import {
  CtControlConfiguration, CTDatatablePaginationParameter,
  CTFieldInfos,
  CtFormContainerHelper, CtModelDatatableOperators, CtSelectControlOptions, CtSelectControlValue,
  CtSortOrderDescriptorParameter,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";

const UserWorkingHoursWorkOrderControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "WorkOrder",
  Type: "WorkOrderDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/WorkOrder/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-work-order-control',
  template: `
    <ct-control [configuration]="configuration"></ct-control>`
})
export class UserWorkingHoursWorkOrderControlComponent implements OnInit {

  configuration: CtControlConfiguration | null = null;

  ngOnInit() {

    this.setup();

  }

  setup() {

    const configuration = CtControlConfiguration
      .create(CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursWorkOrderControl))
      .setTheme(CtThemeTypes.MATERIAL);

    (configuration
      .options as CtSelectControlOptions)
      .setLookupFilter(() => {

        const filter = CtModelDatatableOperators
          .create()
          .setPagination(new CTDatatablePaginationParameter())

        if (filter.Pagination) filter.setPagination(new CTDatatablePaginationParameter())

        filter
          .Pagination
          ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(new CtSortOrderDescriptorParameter().setField('Name')))

        return filter;

      })
      .setLookupResponseMapper((response: CtWebapiGenericResponse<any>) => {

        return (<Array<any>>response.Result.DataSource ?? [])
          .map((elm: any) => {

            return CtSelectControlValue
              .create()
              .setLabel(elm.Description)
              .setValue(elm.Code);

          });

      })
      .setNullValueEnabled(true)
      .notifyValuesUpdated();

    this.configuration = configuration;

  }

}
