// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectName: "RepettoTest",
  dev: {
    REST_API_SERVER: "https://repettomanager.test.api.testctsolution.it/api"
  },
  variables: {
    defaults: {
      inventoryOid: 1
    }
  },
  customTranslationsDirectory: '/assets/custom-translations',
  defaultLang: 'it'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
