import {
  CtButtonConfiguration,
  CtCardConfiguration,
  CtCardIconConfiguration,
  CtCardIconPosition, CtCardTemplate
} from "@ctsolution/ct-framework";

//# region BIG CARDS
export const NEW_MOVEMENT_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.newMovementLabel")
  .setDescription("dashboard.manageItemMovementsLabel")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setIcon("swap_horiz")
      .setClass("bg-beaver-alt text-white")
      .setPosition(CtCardIconPosition.SUFFIX))
  .setClass("bg-beaver-alt text-white")
  .setButton(CtButtonConfiguration.create().setIcon("arrow_forward"));
export const NEW_ORDER_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.newOrderLabel")
  .setDescription("dashboard.manageOrderMovementsLabel")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setIcon("local_shipping")
      .setPosition(CtCardIconPosition.SUFFIX))
  .setClass("bg-beaver text-white")
  .setButton(CtButtonConfiguration.create().setIcon("arrow_forward"));

export const IMPORT_DATA_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("itemImport.title")
  .setDescription("itemImport.subTitle")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setIcon("publish")
      .setClass("bg-beaver-alt text-white")
      .setPosition(CtCardIconPosition.SUFFIX))
  .setClass("bg-purple text-white")
  .setButton(CtButtonConfiguration.create().setIcon("arrow_forward"));

//# endregion BIG CARDS

//# region CARDS
export const ITEM_LIST_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("item-datatable")
  .setDescription("dashboard.viewAndManageItemListLabel")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-info text-white")
      .setIcon("build"));
export const ITEM_CREATE_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.newItemLabel")
  .setDescription("dashboard.insertNewStockItemLabel")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-danger text-white")
      .setIcon("exposure_plus_1"));
export const MODULE_CREATE_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.newModuleLabel")
  .setDescription("dashboard.insertNewModuleToWarehouseLabel")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-inverse text-white")
      .setIcon("exposure_plus_1"));
//# endregion CARDS

//# region COUNTERS
export const ENTRY_HANDLING_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.preparingMovementsLabel")
  .setTemplate(CtCardTemplate.COUNTER)
  .setClass("bg-warning text-white");
export const TRANSIT_HANDLING: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.transitMovementsLabel")
  .setTemplate(CtCardTemplate.COUNTER)
  .setClass("bg-inverse text-white");
//# endregion COUNTERS
