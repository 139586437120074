import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN, SnackbarService} from "@ctsolution/ct-framework";

import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {delay, map} from "rxjs/operators";
import {ModuleCreateFormComponent} from "./module-create-form/module-create-form.component";
import {
  ItemController,
  ModuleCreationCheckResult, ModuleCreationRequestParameter,
  ModuleCreationRequestResult
} from "../../../../_core/controllers/item.controller";
import {ItemHandling} from "../../../../_core/classes/item-handling";


@Component({
  selector: "app-module-create",
  templateUrl: "./module-create.component.html",
  styleUrls: ["./module-create.component.scss"],
  providers: [ItemController]
})
export class ModuleCreateComponent implements OnInit {

  @ViewChild('ModuleCreateForm') moduleCreateForm: ModuleCreateFormComponent | null = null;

  loadingData = true;
  moduleCreationCheckResult: ModuleCreationCheckResult | null = null;
  moduleCreationRequestResult: ModuleCreationRequestResult | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  previewButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("generateMovementsLabel")
    .setAction(() => this.onPreview())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  finalizeButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("confirm")
    .setAction(() => this.onPreview(true))
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ItemHandling,
    private controller: ItemController,
    private dialogRef: MatDialogRef<ModuleCreateComponent>,
    private _snackbar: SnackbarService
  ) {

  }

  ngOnInit() {

    this.checkModuleAvailability(this.data.Module?.Oid ?? -1)

  }

  checkModuleAvailability(oid: number) {

    this.loadingData = true;

    if (!this.data.Module?.Oid) {

      this.onCancel();
      return;

    }

    this.controller
      .moduleCreationCheck(oid)
      .pipe(
        delay(2000),
        map(((result: CtWebapiGenericResponse<ModuleCreationCheckResult>) => {

          this.loadingData = false;
          return result;

        }))
      ).subscribe((result: CtWebapiGenericResponse<ModuleCreationCheckResult>) => this.moduleCreationCheckResult = result.Result);


  }

  onCancel() {

    this.dialogRef
      .close();

  }

  onPreview(submit = false) {

    const form = this.moduleCreateForm?.form;

    form?.markAllAsTouched();

    if (!form) return;

    if (form.valid) {

      const parameter: ModuleCreationRequestParameter = ModuleCreationRequestParameter
        .create()
        .setItemModuleOid(this.data.Module?.Oid ?? 0)
        .setQty(form.value.qty)
        .setLocationToOid(form.value.LocationToOid);

      if (submit) parameter.setFinalize(true);

      this.controller
        .moduleCreationRequest(parameter)
        .subscribe((response: CtWebapiGenericResponse<ModuleCreationRequestResult>) => {

          this.moduleCreationRequestResult = response.Result;

          if (submit) {

            this._snackbar.open("CT_MESSAGES.success_operation");

            this.dialogRef
              .close()

          }

        });

    }

  }

}
