import {Component} from '@angular/core';
import {
  CtControlConfiguration, CtControlValidator, CTFieldInfos,
  CtFormContainerHelper, CtSelectControlOptions, CtSelectControlValue,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";

const UserWorkingHoursActivityTypeControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "ActivityType",
  Type: "ActivityTypeDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/ActivityType/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false,
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-activity-type-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursActivityTypeControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursActivityTypeControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setValidators([
      CtControlValidator.create({name: 'required'} as CtControlValidator)
    ])
    .setOptions(
      CtSelectControlOptions
        .create()
        .setLookupResponseMapper((response: CtWebapiGenericResponse<any>) => {

          // riordino l'array disponibile mettendo le ore lavorate in cima
          const dataSource = response.Result.DataSource;

          // Cerca l'oggetto con il nome "Ore lavorate".
          const oreLavorateObject = dataSource
            .find((item: any) => {

              const description = item.Description.toLowerCase();

              return description.includes('lavor') || description.includes('job') || description.includes('work');

            });

          if (oreLavorateObject) {

            const index = dataSource.indexOf(oreLavorateObject);

            if (index !== -1) dataSource.splice(index, 1);

            // Inserisci l'oggetto nella parte superiore dell'array.
            dataSource.unshift(oreLavorateObject);

          }

          return (dataSource ?? [])
            .map((elm: any) => {

              return CtSelectControlValue
                .create()
                .setLabel(elm.Description)
                .setValue(elm.Code);

            });
        })
    )

}
