import {Component, Input} from '@angular/core';
import {
  StatusToggleComponentButtonConfiguration,
  StatusToggleComponentConfiguration
} from "./status-toggle-component.configuration";

@Component({
  selector: 'app-status-toggle-component',
  templateUrl: './status-toggle-component.component.html',
  styleUrls: ['./status-toggle-component.component.scss']
})
export class StatusToggleComponentComponent<T> {

  @Input() configuration: StatusToggleComponentConfiguration<T> = StatusToggleComponentConfiguration.create<T>();

  getColor = (value: StatusToggleComponentButtonConfiguration<T>) => this.isDisabled(value) ? `#0000001F` : `var(--handlingState${value.value})`;

  setValue(value: T) {

    if (this.configuration.control?.disabled) return;

    if (this.configuration.control?.value === value) return;

    this.configuration
      .control
      ?.setValue(value);

  }

  isDisabled = (button: StatusToggleComponentButtonConfiguration<T>) => this.configuration?.incremental && (this.configuration.control?.value > button.value);

}
