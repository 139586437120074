import {Component, Input} from "@angular/core";
import {
  CtBinaryOperator,
  CtDatatableConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelService
} from "@ctsolution/ct-framework";
import {ModuleAddItemService} from "./module-add-item/module-add-item.service";
import {ModuleEditItemService} from "./module-edit-item/module-edit-item.service";
import {ItemHandling} from "../../../../_core/classes/item-handling";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../../_core/constants";
import {Handling} from "../../../../_core/classes/handling";
import {ChildModuleItemRouteData} from "../../../../_core/route-data/child-module-item.route-data";
import {BeaverItemStock} from "../../../../_core/classes/beaver-item-stock";

@Component({
  selector: "app-module-items",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class ModuleItemsComponent {

  @Input() referenceId: string | null = null;

  configuration: CtModelConfiguration<ModuleItemsComponent> | null = null;

  childModuleModelConfiguration: CtModelConfiguration<ItemHandling> = CtModelConfiguration
    .create<ItemHandling>()
    .setRouteData(ChildModuleItemRouteData());

  datatableConfiguration: CtDatatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION();

  get moduleHasItems(): boolean {

    return !!this.datatableConfiguration.rows.length;

  }

  constructor(
    private moduleEditItemService: ModuleEditItemService,
    private moduleAddItemService: ModuleAddItemService,
    private modelService: CtModelService<ItemHandling>) {
  }

  ngAfterViewInit() {

    if (!this.referenceId) return;

    this.configuration = CtModelConfiguration
      .create<ModuleItemsComponent>()
      .setRouteData(ChildModuleItemRouteData())
      .setCTDatatableConfiguration(this.datatableConfiguration)
      .setFilterValues([
        CTModelDatatableFilter
          .create()
          .setField('Module.Oid')
          .setValue(this.referenceId)
          .setOperatorType(CtBinaryOperator.Equal)
      ])
      .setFieldsCustomTemplates([{prop: "Module", visible: false}])
      .setOnCreate(() => {

        if (!this.referenceId) return;

        this.moduleAddItemService
          .open(ItemHandling.create().setModule(<BeaverItemStock>{ItemOid: +this.referenceId}))
          .afterClosed()
          .subscribe((handlings?: Handling[]) => {

            if (handlings?.length) {

              handlings
                .forEach((handling, idx) => {

                  this.modelService
                    .putInfos(this.childModuleModelConfiguration, handling)
                    ?.subscribe(() => {

                      if (idx === (handlings.length - 1)) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

                    });

                });

            }

          });

      })
      .setOnEdit((moduleItem: ItemHandling) => {

        this.moduleEditItemService
          .open(ItemHandling.create(moduleItem))
          .afterClosed()
          .subscribe((reload) => {

            if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

          });

      });

  }

}
