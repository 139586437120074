import {FormControl} from "@angular/forms";

export class StatusToggleComponentConfiguration<T> {

  control: FormControl | null = null;
  values: StatusToggleComponentButtonConfiguration<T>[] = [];
  direction: 'col' | 'row' = 'row';
  incremental = false;

  public static create = <T>(): StatusToggleComponentConfiguration<T> => new StatusToggleComponentConfiguration<T>();

  setControl(value: FormControl | null) {

    this.control = value;
    return this;

  }

  setValues(value: Array<StatusToggleComponentButtonConfiguration<T>>) {

    this.values = value;
    return this;

  }

  setDirection(value: 'row' | 'col') {

    this.direction = value;
    return this;

  }

  setIncremental(value: boolean) {

    this.incremental = value;
    return this;

  }

}

export class StatusToggleComponentButtonConfiguration<T> {

  label: string | null = null;
  icon: string | null = null;

  private constructor(public value: T) {
  }

  public static create = <T>(value: T): StatusToggleComponentButtonConfiguration<T> => new StatusToggleComponentButtonConfiguration<T>(value);

  setLabel(value: string | null) {

    this.label = value;
    return this;

  }

  setIcon(value: string | null) {

    this.icon = value;
    return this;

  }

}
