import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {WorkOrderEditComponent} from "./work-order-edit.component";
import {CtFrameworkModule, CtModelModule} from "@ctsolution/ct-framework";
import {CustomerAddressListComponent} from './customer-address-list/customer-address-list.component';
import {MatLegacyListModule} from "@angular/material/legacy-list";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {FlexModule} from "@angular/flex-layout";
import {MatLegacyCardModule} from "@angular/material/legacy-card";

@NgModule({
  declarations: [
    WorkOrderEditComponent,
    CustomerAddressListComponent
  ],
    imports: [
        CommonModule,
        CtModelModule,
        MatLegacyListModule,
        MatButtonModule,
        FlexModule,
        MatLegacyCardModule,
        CtFrameworkModule
    ],
  exports: [
    WorkOrderEditComponent
  ]
})
export class WorkOrderEditModule {
}
