import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CtButtonModule, CtControlModule, CtDatatableModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {ItemSearchComponent} from "./item-search/item-search.component";
import {ItemSelectionComponent} from "./item-selection/item-selection.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {ItemSelectionService} from "./item-selection.service";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ItemSelectionImportModule} from "./item-selection-import/item-selection-import.module";

@NgModule({
  declarations: [ItemSelectionComponent, ItemSearchComponent],
  imports: [
    CommonModule,
    CtDatatableModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CtControlModule,
    MatCardModule,
    CtButtonModule,
    MatDialogModule,
    ItemSelectionImportModule,
    CtFrameworkModule
  ],
  exports: [ItemSearchComponent],
  providers: [ItemSelectionService]
})
export class ItemSelectionModule {
}
