import {DataRequest} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {ControllerHelper} from "./controller.helper";
import {CTModelDatatableFilter, CtSelectControlValue} from "@ctsolution/ct-framework";
import {UnitsOfMeasurements} from "../classes/units-of-measurements";

const CONTROLLER: string[] = ["unitsofmeasurements"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController(CONTROLLER);

@Injectable()
export class UnitsOfMeasurementController {

  constructor(private _helper: ControllerHelper) {
  }

  get = (filters?: CTModelDatatableFilter[], attempt = 3): Promise<Array<UnitsOfMeasurements>> => {

    return new Promise<Array<UnitsOfMeasurements>>((resolve) => {

      this._helper.list(CONTROLLER_REQUEST()
        .setBody({
          Filters: filters ?? []
        }))
        .then(response => resolve((response.DataSource ?? []) as Array<UnitsOfMeasurements>));

    });

  };

}
