import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter, DeleteConfirmService,
} from "@ctsolution/ct-framework";
import {UserWorkingHoursPicturesConfiguration} from "./user-working-hours-pictures.configuration";
import {UserWorkingHoursFileController} from "../../../../../_core/controllers/user-working-hours-file.controller";
import {BeaverFile} from "../../../../../_core/classes/beaver-file";
import {MAT_LEGACY_DIALOG_DATA} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-user-working-hours-pictures',
  templateUrl: './user-working-hours-pictures.component.html',
  styleUrls: ['./user-working-hours-pictures.component.scss'],
  providers: [UserWorkingHoursFileController]
})
export class UserWorkingHoursPicturesComponent implements OnInit {

  @ViewChild('fileUploader') fileUploader: any | null = null;

  pictures: Array<BeaverFile> = []
  loading = true;
  selectedIndex: number | null = null;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: UserWorkingHoursPicturesConfiguration,
    private userWorkingHoursFileController: UserWorkingHoursFileController,
    private deleteConfirm: DeleteConfirmService
  ) {
  }

  ngOnInit() {

    this.getList();

  }

  onUpload(event: any) {

    const files = (event.target as HTMLInputElement).files ?? [];

    if (!files.length) return;

    const promises = [];

    for (let i = 0; i < files.length; i++) {

      const file = files[i];

      const parameter: BeaverFile = BeaverFile
        .create()
        .setFile(file)
        .setUserWorkingHours(this.data.userWorkingHours);

      const promise = this.userWorkingHoursFileController
        .create(parameter)
        .toPromise();

      promises.push(promise);

    }

    Promise.all(promises)
      .then(() => this.getList())
      .catch((error) => console.error(error));

  }

  getList() {

    this.loading = true;

    const filter: CTModelDatatableFilter = CTModelDatatableFilter
      .create()
      .setField("UserWorkingHours.Oid")
      .setValue(this.data.userWorkingHours?.Oid)
      .setOperatorType(CtBinaryOperator.Equal);

    this.userWorkingHoursFileController
      .get([filter])
      .then((result: CtModelConfiguration<Array<BeaverFile>>) => {
        this.pictures = (<Array<BeaverFile>>result.DataSource ?? []).map(elm => BeaverFile.create(elm));
        this.loading = false;
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
      });

  }

  selectPicture(selectedIndex: number | null) {

    this.selectedIndex = this.selectedIndex === selectedIndex ? null : selectedIndex;

  }

  deletePicture(file: BeaverFile) {

    if (!file.Oid) return;

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe(confirm => {

        if (confirm) {

          this.userWorkingHoursFileController
            .delete(file)
            ?.subscribe(() => this.getList());

        }

      })

  }

}
