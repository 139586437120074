import {Component} from "@angular/core";
import {BeaverImportConfiguration} from "../../layouts/beaver-import/beaver-import.configuration";
import {FileController} from "../../_core/controllers/file.controller";

@Component({
  selector: "app-item-import",
  template: `
    <beaver-import [configuration]="configuration"></beaver-import>`,
  providers: [FileController]
})
export class ItemImportComponent {

  configuration: BeaverImportConfiguration = BeaverImportConfiguration
    .create()
    .setImportSubscription((file: File) => this.fileController.itemImport(file))
    .setTitle("itemImport.title")
    .setSubtitle("itemImport.subTitle")
    .setDescription("itemImport.description")
    .setTemplate({
      path: `assets/templates/BVSItemImportTemplate.csv`,
      fileName: `BVSItemImportTemplate${new Date().getFullYear()}`
    });

  constructor(private fileController: FileController) {
  }

}
