<div class="module-create-form">

  <div>

    <p> {{ 'ModuleCreateForm.Label' | translate:{possibileModuleQty: model?.PossibileModuleQty} }} </p>

    <p> {{ 'ModuleCreateForm.Warning' | translate }} </p>

  </div>

  <form [formGroup]="form">

    <div>

      <p> {{ 'ModuleCreateForm.QuantityLabel' | translate }} </p>

      <div
        class="p-10">

        <ct-control [configuration]="qtyControl"></ct-control>

      </div>

    </div>

    <div>

      <p> {{ 'ModuleCreateForm.DestinationLabel' | translate }} </p>

      <div
        class="p-10">

        <ct-control [configuration]="locationToControl"></ct-control>

      </div>

    </div>

  </form>

</div>
