import {
  CtButtonConfiguration,
  CtCardConfiguration,
  CtCardIconConfiguration,
  CtCardIconPosition
} from "@ctsolution/ct-framework";

export const CONTACT_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.contactsLabel")
  .setDescription("dashboard.contactsLabelDescription")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-info text-white")
      .setIcon("exposure_plus_1"));
