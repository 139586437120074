import {Injectable} from "@angular/core";
import {ReportController} from "../../_core/controllers/report.controller";
import {HandlingOriginEnum} from "../../_core/enum/handling-origin.enum";
import {Handling} from "../../_core/classes/handling";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {CTGeneralService, CtModelConfiguration, CtModelService, SnackbarService} from "@ctsolution/ct-framework";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HandlingService {

  constructor(
    private _model: CtModelService<Handling>,
    private _snackbar: SnackbarService,
    private reportController: ReportController,
    public general: CTGeneralService,
    private router: Router) {
  }

  downloadHandlingPDF(handlingOid: number) {

    this.reportController
      .getHandlingPDF(handlingOid, true)
      .subscribe();

  }

  getHandlingTypeByRouteValue(originPathValue: string | null): HandlingOriginEnum {

    switch (originPathValue) {

      case "order":
        return HandlingOriginEnum.Supplier;
      case "movement":
      default:
        return HandlingOriginEnum.Location;

    }

  }

  handlingPut(configuration: CtModelConfiguration<Handling>, handling: Handling, redirectEnabled = true) {

    this._model
      .putInfos(configuration, handling)
      ?.subscribe((response: CtWebapiGenericResponse<number>) => {

        this._snackbar.open("CT_MESSAGES.success_operation");

        if (redirectEnabled) {

          if (response.Result && !isNaN(response?.Result)) {

            this.router.navigate(["handling", handling.HandlingOrigin === HandlingOriginEnum.Supplier ? 'order' : 'movement', "edit", response.Result]);

          } else {

            this.general
              .reloadCurrentRoute();

          }

        }

      });

  }

}
