import {Component, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY} from "@ctsolution/ct-framework";
import {ModuleCreateService} from "./module-create/module-create.service";
import {ItemHandling} from "../../../_core/classes/item-handling";
import {ModuleItemsComponent} from "./module-items/module-items.component";
import {BeaverItemStock} from "../../../_core/classes/beaver-item-stock";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-catalog-detail",
  templateUrl: "./catalog-detail.component.html",
  styleUrls: ["./catalog-detail.component.scss"]
})
export class CatalogDetailComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;
  @ViewChild(ModuleItemsComponent) moduleItemsComponent: ModuleItemsComponent | null = null;

  pId: string | null = null;
  createButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("generateModulesLabel")
    .setIcon("auto_fix_normal")
    .setAction(() => this.createModule())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private moduleCreateService: ModuleCreateService) {

    this.pId = this.route.snapshot.paramMap.get("id");

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        this.route
          .data
          .subscribe(data => {

            if (qp["Name"]) {

              setTimeout(() => {

                this.translate
                  .get("detailOf", {name: qp["Name"]})
                  .subscribe(result => data['title'] = result);

              })

            }

            data['headerActions'] = this.headerActions;
            data['urls'] = [];

          })

      });

  }

  createModule() {

    if (!this.pId) return;

    this.moduleCreateService
      .open(ItemHandling.create().setModule(<BeaverItemStock>{ItemOid: +this.pId}))
      .afterClosed()
      .subscribe(result => {

        if (result) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });
  }

}
