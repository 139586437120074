import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CtModelRouteData} from "@ctsolution/ct-framework";

export const ContactRouteData = (): CtModelRouteData => CtModelRouteData
  .create()
  .setController("contact");

export const ContactPlaceRouteData = (): CtModelRouteData => CtModelRouteData
  .create()
  .setController("contactplace");

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class ContactModule {
}
