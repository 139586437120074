import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input, AfterViewInit
} from "@angular/core";
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {MediaMatcher} from "@angular/cdk/layout";
import {MenuItems} from "../menu/menu-items";
import {Menu} from "../menu/_classes/menu-item.interface";
import {BehaviorSubject, Observable} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: "app-vertical-sidebar",
  templateUrl: "./vertical-sidebar.component.html",
  styleUrls: []
})
export class VerticalAppSidebarComponent implements OnDestroy, AfterViewInit {

  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _mobileQueryListener: () => void;
  status = true;
  showMenu = "";
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  // @ts-ignore
  menuItems$: BehaviorSubject<Menu[]> = new BehaviorSubject([]);

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private menuItems: MenuItems,
    private router: Router
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngAfterViewInit() {

    this.setup();

  }


  async setup() {

    const items = await this.menuItems.getMenuItems();

    this.menuItems$.next(items);

  }

  addExpandClass(element: any) {

    if (element === this.showMenu) {

      this.showMenu = "0";

    } else {

      this.showMenu = element;

    }

  }

  subclickEvent(): void {

    this.status = true;

  }

  handleNotify(menuitem?: Menu) {

    if (menuitem) {

      let states: string[] = ["/"];

      if (menuitem.separator?.length) {

        states = states.concat(menuitem.separator.map(elm => elm.name));

      }

      states.push(menuitem.state);

      this.router
        .navigate(states);

    }

    if (window.innerWidth < 1024) {

      this.notify.emit(!this.showClass);

    }

  }

  ngOnDestroy(): void {

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

}
