import {Component} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Router, NavigationEnd, ActivatedRoute, Data} from "@angular/router";
import {filter, map, mergeMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styles: [`.page-breadcrumb {

    .breadcrumb {

      margin: 10px auto !important;

    }

    .header-actions {
      margin: auto 10px;
    }
  }`]
})
export class AppBreadcrumbComponent {

  pageInfo: Data = Object.create(null);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService
  ) {

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === "primary"))
      .pipe(mergeMap((route) => route.data))
      // tslint:disable-next-line - Disables all
      .subscribe((event) => {

        const title: string | null = event["title"] ?? environment.projectName;
        // tslint:disable-next-line - Disables all
        this.titleService.setTitle(title ?? "");
        this.pageInfo = event;
      });

  }

}
